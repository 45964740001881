import axios from "axios";
// export const API = process.env.API || "/";
export const ENV = process.env.ENV_NAME || "dev";
import { sha256 } from "js-sha256";

export let API;
if (process.env.NODE_ENV === "production") {
  API = process.env.PROD_API;
}
if (process.env.NODE_ENV === "development") {
  API = process.env.DEV_API;
}

const myHash = () => {
  const time = Date.now();
  return {
    time,
    hash: sha256(`${time}|${process.env.HASH_CODE}`),
  };
};

const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
  },
  baseURL: API,
});

const headers = () => ({});

const getToken = () => {
  return localStorage.getItem(`core-platform-admin-${ENV}-uuid`)?.token || "";
};

const GET = (url, params = {}, isToken = false) => {
  if (isToken) {
    return axiosInstance.get(url, {
      params: {
        ...params,
      },
      headers: {
        ...headers(),
        authorization: `Bearer ${getToken()}`,
      },
    });
  }

  return axiosInstance.get(url, {
    params,
  });
};

const DELETE = (url, params = {}, isToken = false) => {
  if (isToken) {
    return axiosInstance.delete(url, {
      params: {
        ...params,
      },
      headers: {
        ...headers(),
        authorization: `Bearer ${getToken()}`,
      },
    });
  }

  return axiosInstance.delete(url, {
    params,
  });
};

const POST = (url, formData, params = {}, isToken = false) => {
  if (isToken) {
    return axiosInstance.post(
      url,
      {
        ...formData,
        ...myHash(),
      },
      {
        params: {
          ...params,
        },
        headers: {
          ...headers(),
          authorization: `Bearer ${getToken()}`,
        },
      }
    );
  }

  return axiosInstance.post(
    url,
    {
      ...formData,
      ...myHash(),
    },
    {
      params,
    }
  );
};

const POSTWithPublicToken = (url, formData, params = {}) => {
  return axiosInstance.post(
    url,
    {
      ...formData,
      // ...myHash(),
    },
    {
      headers: {
        ...headers(),
        authorization: `Bearer 7d9815c8e4f30683770ee304d07fe5b3a1be0f279108c78bf55f5d8ed7e730d4`,
      },
      params: {
        ...params,
      },
    }
  );
};

// tslint:disable-next-line: max-line-length
const FILE = (url, formData, params = {}, isToken = false) => {
  if (isToken) {
    return axiosInstance.post(url, formData, {
      params: {
        ...params,
      },
      headers: {
        ...headers(),
        authorization: `Bearer ${getToken()}`,
      },
    });
  }

  return axiosInstance.post(url, formData, {
    params,
  });
};

const PUT = (url, formData, params = {}, isToken = false) => {
  if (isToken) {
    return axiosInstance.put(
      url,
      {
        ...formData,
        ...myHash(),
      },
      {
        params: {
          ...params,
        },
        headers: {
          ...headers(),
          authorization: `Bearer ${getToken()}`,
        },
      }
    );
  }

  return axiosInstance.put(
    url,
    {
      ...formData,
      ...myHash(),
    },
    {
      params,
    }
  );
};

export {
  GET,
  POST,
  PUT,
  DELETE,
  getToken,
  FILE,
  axiosInstance,
  POSTWithPublicToken,
};
